import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache/cache.service';
import {
  Apartment,
  ApartmentRole,
  Fault,
  FaultCode,
  FormAction,
  Scope,
  TenantUnit,
  TenantUnitRole,
  User,
} from '../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordModal } from 'src/app/shared/modals/forgot-password-modal/forgot-password-modal.component';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  isLoading: boolean;
  isResettingCache: boolean;
  actionRequired: boolean;

  readonly currentYear: number;
  emailLoginForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private cache: CacheService,
    private renderer2: Renderer2,
    private modalService: NgbModal
  ) {
    this.currentYear = new Date().getFullYear();
    this.isLoading = false;
    this.isResettingCache = false;
    this.actionRequired = false;
    this.emailLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  ngOnInit() {
    this.renderer2.addClass(document.body, 'login-body');
  }

  ngOnDestroy() {
    this.renderer2.removeClass(document.body, 'login-body');
  }

  async onSubmit() {
    const f = this.emailLoginForm;

    const email: string = f.value.email;
    const password: string = f.value.password;

    this.isLoading = true;
    try {
      await this.cache.signInByEmail(email, password);

      await this.router.navigate(['/tenant-unit/choose']);
      this.isLoading = false;
      return;
    } catch (x) {
      const err = new Fault(x);
      if (err instanceof Fault) {
        switch (err.code) {
          case FaultCode.WrongPassword:
          case FaultCode.NotFound:
            f.controls.password.setErrors({ failure: 'Fel email eller lösenord' });
            f.controls.email.setErrors({ failure: 'Ett fel uppstod' });
            break;
          case FaultCode.Unauthorized:
            console.log('Unauthorized');
            this.actionRequired = true;
            break;
          default:
            f.controls.email.setErrors({ failure: 'Ett fel uppstod' });
            f.controls.password.setErrors({ failure: 'Fel email eller lösenord' });
            break;
        }
      } else {
        f.controls.email.setErrors({ failure: 'Ett fel uppstod' });
        f.controls.password.setErrors({ failure: 'Ett fel uppstod' });
      }
    }

    this.isLoading = false;
  }

  async openForgotPasswordModal() {
    const modalRef = this.modalService.open(ForgotPasswordModal, {
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.formAction = FormAction.Update;
  }

  async onResetCacheClick() {
    if (this.isLoading || this.isResettingCache) {
      return;
    }

    this.isResettingCache = true;

    await this.cache.resetCache();
    await this.cache.sleep(1500);

    window.location.reload();
  }

  get fc() {
    return this.emailLoginForm.controls;
  }

  get fv() {
    return this.emailLoginForm.value;
  }
}
